import PageLoader from '~/components/ui/PageLoader'
import { useFitTestRedirect } from '~/hooks/useFitTestRedirect'

export const FitTestRedirect = ({ children }: { children: JSX.Element }) => {
  const { mayRedirectToOnboarding } = useFitTestRedirect()

  if (mayRedirectToOnboarding) {
    return <PageLoader />
  }

  return children
}
