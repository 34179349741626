import getConfig from 'next/config'
import { useRouter } from 'next/router'

import { useGetUserQuery } from '~/graphql/generated/schema'
const { publicRuntimeConfig } = getConfig()

const RESEARCH_ROLE = 'research'
const FLAGSHIP_URL = publicRuntimeConfig.auth.flagship.url

export function useResearchRedirect() {
  const router = useRouter()
  const { data, loading, error } = useGetUserQuery({ fetchPolicy: 'cache-and-network' })
  if (data) {
    const { roles } = data.me
    if (roles.includes(RESEARCH_ROLE)) {
      router.push(FLAGSHIP_URL)
      // slight overload of the term-- we're not loading roles anymore, but we are about to start loading a new
      // page, so caller should keep behaving as if we're loading until the redirect takes effect
      return { loading: true, data: undefined, error: undefined }
    }
  }

  return { loading, data, error }
}
