import { useCallback, useContext } from 'react'

import { EventsContext } from '~/events/EventsContext'
import {
  CommonGameEventProps,
  EventClickNamesValues,
  EventClickTypeValues,
  EventName,
  EventScreenNameValues,
} from '~/events/eventTypes'

export interface TrackCtaClickData extends CommonGameEventProps {
  /**  text = the text/name of the CTA */
  text: string
  /**  type = the type of CTA {button, interstitial, banner} */
  type: EventClickTypeValues
  /**  destination = the screen name that the CTA directs the user to */
  destination?: EventScreenNameValues
  /**  click_name = the name of the click */
  click_name: EventClickNamesValues
  /**  value = the value of the click */
  value?: string
}

/** This hook can be used for all kind of clicks,
 * if the click have very custom logic for tracking, we can extract it out into another hook
 * multiple functions can be used from 1 single hook like trackCTA, trackSignOut
 *  */
const useTrackClick = () => {
  /**  currentScreen will be set by useTrackScreenView event */
  const {
    clients: { lumosEventApi },
    currentScreen,
  } = useContext(EventsContext)

  /**
   * data is master set of properties across vendors, this function will decide, which client gets what properties
   * */
  const trackCta = useCallback(
    (data: TrackCtaClickData) => {
      lumosEventApi.track(EventName.Click, { ...data, screen_name: currentScreen })
    },
    [lumosEventApi, currentScreen],
  )

  return { trackCta }
}

export default useTrackClick
