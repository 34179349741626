import React from 'react'

import type { ApolloError } from '@apollo/client'
import styled from 'styled-components'

import { Error } from '~/components/ui/sharedStyledComponents'
import SkeletonLoader from '~/components/ui/SkeletonLoader'

interface QueryLoaderProps {
  loading: boolean
  error: ApolloError | undefined
  data: any
  children: any
  LoadingComponent?: React.ReactNode
}

/**
 * QueryLoader conditionally renders the LoadingOrb, an Error, or data returned from query
 * LoadingComponent can be passed in as a prop to customize the loading spinner
 * page level loaders are <LoadingOrb />, sectional loaders are <SkeletonLoader />
 * SkeletonLoader are very specific to the component dimension, thus it should be passed from the component
 */
const QueryLoader: React.FC<QueryLoaderProps> = ({
  loading,
  error,
  data,
  children,
  LoadingComponent = <QueryLoaderSkeleton />,
}) => {
  // slightly tricky, since we generally want errors to show in preference to data, but we'd like
  // reloading to overrule errors
  const reloadingError = loading && error

  if (reloadingError) {
    return LoadingComponent
  }

  if (error) {
    return <Error />
  }

  if (data) {
    return children
  }

  if (loading) {
    return LoadingComponent
  }

  if (!data) {
    return <Error />
  }
}

export default QueryLoader

export const QueryLoaderSkeleton = () => {
  return (
    <SkeletonBarContainer>
      <SkeletonBar />
    </SkeletonBarContainer>
  )
}

const SkeletonBarContainer = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SkeletonBar = styled(SkeletonLoader)`
  width: 90%;
  height: 100%;
`
