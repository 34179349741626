import React from 'react'

import { useReactiveVar } from '@apollo/client'
import dynamic from 'next/dynamic'

import PageLoader from '~/components/ui/PageLoader'
import { ageGateVar } from '~/graphql/reactive-vars'

const DynamicDateOfBirthForm = dynamic(() => import('~/components/auth/DateOfBirthForm'), {
  loading: () => <PageLoader />,
  ssr: false,
})

const DynamicUserNotEligible = dynamic(() => import('~/components/auth/UserNotEligible'), {
  loading: () => <PageLoader />,
  ssr: false,
})

interface AgeGateWrapperProps {
  children: JSX.Element
}

/**
 * AgeGating can act based on ageGatingVar
 * if state is 'OK', it will not do anything
 * if state is 'UNDERAGE_DATE_OF_BIRTH', it will block user for being underage
 * if state is 'MISSING_DATE_OF_BIRTH', it will show a modal to ask for date of birth
 */
export const AgeGateWrapper: React.FC<AgeGateWrapperProps> = ({ children }): JSX.Element => {
  const ageGateState = useReactiveVar(ageGateVar)

  if (ageGateState === 'MISSING_DATE_OF_BIRTH') {
    return (
      <React.Suspense fallback={<PageLoader />}>
        <DynamicDateOfBirthForm
          onSuccess={() => {
            ageGateVar('OK')
          }}
        />
      </React.Suspense>
    )
  }

  if (ageGateState === 'UNDERAGE_DATE_OF_BIRTH') {
    return (
      <React.Suspense fallback={<PageLoader />}>
        <DynamicUserNotEligible />
      </React.Suspense>
    )
  }

  return <>{children}</>
}
