import { useEffect } from 'react'

import { ApolloClient } from '@apollo/client'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

import { PUBLIC_PATHS } from '~/constants'
import { useUpdateLastUsedLanguageMutation } from '~/graphql/generated/schema'

const useUpdateLastUsedLanguage = (client?: ApolloClient<any>) => {
  const router = useRouter()
  const currentPath = router.pathname
  // as useUpdateLastUsedLanguage is used in withApollo, i.e. in _app.tsx,
  // we need to check if the current path is not exactly '/' & not in PUBLIC_PATHS
  const istPathAllowed = currentPath !== '/' && !PUBLIC_PATHS.some((path) => currentPath.startsWith(path))

  const [updateLastUsedLanguage] = useUpdateLastUsedLanguageMutation({ client })
  const { lang } = useTranslation()

  useEffect(() => {
    if (istPathAllowed) {
      updateLastUsedLanguage({
        variables: {
          lastUsedLanguage: lang,
        },
      })
    }
  }, [istPathAllowed, lang, updateLastUsedLanguage])
}

export default useUpdateLastUsedLanguage
