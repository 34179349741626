import { useReactiveVar } from '@apollo/client'

import QueryLoader from '~/components/QueryLoader'
import PageLoader from '~/components/ui/PageLoader'
import { currentUserVar } from '~/graphql/reactive-vars'
import { useResearchRedirect } from '~/hooks/useResearchRedirect'

const ResearchRedirectComponent = ({ children }: { children: React.ReactNode }) => {
  const { loading, error, data } = useResearchRedirect()

  return (
    <QueryLoader loading={loading} error={error} data={data} LoadingComponent={<PageLoader />}>
      {children}
    </QueryLoader>
  )
}

export const ResearchRedirect = ({ children }: { children: React.ReactNode }) => {
  const currentUser = useReactiveVar(currentUserVar)
  const hasUser = !!currentUser?.id

  if (!hasUser) {
    // if we don't have a user, just return without doing anything
    // this is done to allow public pages which are wrapped under withApollo example /family-invitation/[invitationId]
    return <>{children}</>
  }

  return <ResearchRedirectComponent>{children}</ResearchRedirectComponent>
}
