type AnyObject = { [key: string]: any }

/**
 * isShallowEqual : compares two objects's 1st level keys, and then checks for equality
 * returns true if both object's values are equal
 */
export const isShallowEqual = (obj1: AnyObject, obj2: AnyObject): boolean => {
  // combine unique 1st level keys from both objects
  const allKeySet = new Set([...Object.keys(obj1), ...Object.keys(obj2)])
  for (const key of Array.from(allKeySet)) {
    if (obj1[key] !== obj2[key]) return false
  }
  return true
}
